import { LoginModal, PrivyProvider, useLogin, usePrivy, type PrivyClientConfig } from '@privy-io/react-auth';
import clsx from 'clsx';
import { useAsync } from 'react-use';
import { createConfig, useConfig, WagmiProvider } from 'wagmi';

import { getLoginDtoFromPrivyLogin } from '@endaoment-frontend/authentication';
import { config } from '@endaoment-frontend/config';
import { WALLET_CONNECT_PROJECT_ID } from '@endaoment-frontend/constants';
import { createTransportsForChains, getConnectors } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import type { LoginDTO } from '@endaoment-frontend/types';
import { LogoIcon } from '@endaoment-frontend/ui/icons';
import { buttonClassNames, Card, Loader } from '@endaoment-frontend/ui/shared';

import { useOAuthFormParams } from '../components/useOAuthFormParams';

import styles from './index.module.scss';

const submitPrivyLoginAsFormSubmit = (submitFormUrl: string, body: LoginDTO) => {
  // Create a form to send the user to the auth server
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = submitFormUrl;
  form.style.display = 'none';

  // Add hidden inputs to the form for each field in the POST body
  (Object.keys(body) as Array<keyof typeof body>).forEach(key => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = body[key];
    form.appendChild(input);
  });

  // Append the form to the body and submit it
  document.body.appendChild(form);
  form.submit();
};
const InnerPage = () => {
  const { submitFormUrl, abortUrl } = useOAuthFormParams();
  const wagmiConfig = useConfig();

  const { login } = useLogin({
    onComplete: async (user, _isNewUser, _wasAlreadyAuthenticated, loginMethod) => {
      if (!submitFormUrl) throw new Error('submitFormUrl is required');
      const loginDto = await getLoginDtoFromPrivyLogin({ wagmiConfig, user, loginMethod });
      submitPrivyLoginAsFormSubmit(submitFormUrl, loginDto);
    },
  });
  const { isModalOpen, ready: isPrivyReady, logout } = usePrivy();
  useAsync(async () => {
    // Do not do anything if the modal is already open or Privy is not ready
    // Calling either login or logout before Privy is ready will result in them doing nothing
    // and the user will see an infinite loading spinner
    if (isModalOpen || !isPrivyReady) return;

    // Force the user to be logged out if we have not processed a login on the current visit
    try {
      await logout();
    } catch (e) {
      console.error(e);
    }

    // Open the login modal
    login();
  }, [isPrivyReady, isModalOpen]);

  const canShowLogin = !!submitFormUrl && isModalOpen;

  return (
    <div className={styles['container']}>
      {canShowLogin ? (
        <>
          <p className={styles['logo']}>
            <LogoIcon width={100} />
            Connect to Endaoment
          </p>
          <Card>
            <LoginModal open />
          </Card>
          {!!abortUrl && (
            <a
              className={clsx(
                buttonClassNames.base,
                buttonClassNames.variants.red,
                buttonClassNames.sizes.small,
                buttonClassNames.fontSize.small,
              )}
              href={abortUrl}>
              Abort
            </a>
          )}
        </>
      ) : (
        <Loader size='l' />
      )}
    </div>
  );
};

const wagmiConfig = createConfig({
  chains: config.chains,
  connectors: getConnectors({
    appName: 'oauth.endaoment',
    appDescription: 'Endaoment',
    appUrl: 'https://oauth.endaoment.org',
    appLogo: 'https://storage.googleapis.com/endaoment-static/privy-logo-header.png',
  }),
  transports: createTransportsForChains(config.chains),
  batch: {
    multicall: true,
  },
  syncConnectedChain: true,

  // These settings are required for the Privy SDK
  ssr: true,
  multiInjectedProviderDiscovery: false,
});
const privyConfig: PrivyClientConfig = {
  appearance: {
    logo: '',
    theme: 'light',
    accentColor: '#676FFF',
    walletList: ['detected_wallets', 'metamask', 'coinbase_wallet', 'wallet_connect'],
    showWalletLoginFirst: false,
    landingHeader: '',
    loginMessage: undefined,
    walletChainType: 'ethereum-only',
  },
  legal: {
    privacyPolicyUrl: routes.docs.privacyPolicy(),
    termsAndConditionsUrl: routes.docs.termsAndConditions(),
  },
  // Create embedded wallets for users who don't have a wallet
  embeddedWallets: {
    createOnLogin: 'users-without-wallets',
  },
  supportedChains: config.chains,
  walletConnectCloudProjectId: WALLET_CONNECT_PROJECT_ID,

  // Make the login modal into a standalone component
  // @ts-expect-error Privy internal api
  _render: {
    standalone: true,
  },
};
const PrivyLoginPage = () => (
  <PrivyProvider appId={config.privyAppId} config={privyConfig}>
    <WagmiProvider config={wagmiConfig}>
      <InnerPage />
    </WagmiProvider>
  </PrivyProvider>
);

export default PrivyLoginPage;
